import React, { CSSProperties, useState, useRef, useEffect } from 'react';
import * as ReactDOM from 'react-dom';

import { getContrastTextColor } from './styles/Colors';

const DefaultButtonColor = '#EFEFEE';
const DefaultSize = 'medium';

type MessageIconProps = {
  iconSize: string;
  iconColor: string;
  iconSrc?: string;
};

function MessageIcon(props: MessageIconProps) {
  const { iconSize, iconColor, iconSrc } = props;

  const containerStyle: React.CSSProperties = {
    height: iconSize,
    width: iconSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: iconColor,
    position: 'relative',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  };

  const imageStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'block',
    color: 'transparent', // Hide broken image icon
    backgroundColor: 'transparent',
    maxWidth: '100%',
    maxHeight: '100%',
  };

  if (iconSrc) {
    return (
      <div style={containerStyle}>
        <img
          src={iconSrc}
          alt="Chat widget icon"
          style={imageStyle}
          onError={(e) => {
            const target = e.currentTarget;
            target.style.display = 'none';
            // Render default SVG
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            svg.setAttribute('viewBox', '0 0 24 24');
            svg.setAttribute('fill', 'none');
            svg.setAttribute('stroke', 'currentColor');
            svg.setAttribute('stroke-width', '2');
            svg.setAttribute('stroke-linecap', 'round');
            svg.setAttribute('stroke-linejoin', 'round');
            Object.assign(svg.style, imageStyle);

            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            path.setAttribute(
              'd',
              'M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z'
            );

            svg.appendChild(path);
            target.parentElement?.appendChild(svg);
          }}
        />
      </div>
    );
  }

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={containerStyle}
    >
      <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
    </svg>
  );
}

type CloseIconProps = {
  iconSize: string;
  iconColor: string;
};
function CloseIcon(props: CloseIconProps) {
  const { iconSize, iconColor } = props;
  const iconStyle: CSSProperties = { height: iconSize, width: iconSize, color: iconColor };
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={iconStyle}
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
}

export type ChatLauncherButtonSizeOption = 'small' | 'medium' | 'large';

type SizeStyleSettings = {
  buttonSize: string;
  iframeBottom: string;
  iconSize: string;
  textLeft: string;
  fontSize: string;
};

export function computeSizeStyleSettings(size: ChatLauncherButtonSizeOption | null | undefined): SizeStyleSettings {
  const BUTTON_MARGIN = 16;
  const IFRAME_GAP = BUTTON_MARGIN / 1.5;
  switch (size) {
    case 'small':
      return {
        buttonSize: '36px',
        iframeBottom: `${36 + BUTTON_MARGIN + IFRAME_GAP}px`,
        iconSize: '14px',
        textLeft: '36px',
        fontSize: '12px',
      };
    case 'large':
      return {
        buttonSize: '64px',
        iframeBottom: `${64 + BUTTON_MARGIN + IFRAME_GAP}px`,
        iconSize: '20px',
        textLeft: '64px',
        fontSize: '18px',
      };
    case 'medium':
    default:
      return {
        buttonSize: '44px',
        iframeBottom: `${44 + BUTTON_MARGIN + IFRAME_GAP}px`,
        iconSize: '18px',
        textLeft: '44px',
        fontSize: '14px',
      };
  }
}

function getTextWidth(text: string, font: string): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return 0;

  context.font = font;
  return context.measureText(text).width;
}

function parsePixelString(pixels: string): number {
  const cleanPixels = pixels.trim().toLowerCase();
  if (!cleanPixels.endsWith('px')) {
    throw new Error('Invalid format: String must end with "px"');
  }
  const numStr = cleanPixels.replace(/\s*px$/, '');
  const num = parseFloat(numStr);

  if (isNaN(num)) {
    throw new Error('Invalid number format');
  }

  return num;
}

const LAUNCHER_FONT_WEIGHT = 500;

function computeExpandedWidth(text: string, textLeftPixels: number, fontSize: string, buttonSize: string): number {
  const fontFamily = getComputedStyle(document.body).fontFamily || 'Arial';
  const font = `${LAUNCHER_FONT_WEIGHT} ${fontSize} ${fontFamily}`;

  const textWidthPixels = getTextWidth(text, font);
  const launcherPaddingRight = text === '' ? 0 : parseFloat(buttonSize) / 2;
  const totalWidth = textLeftPixels + textWidthPixels + launcherPaddingRight;

  return totalWidth;
}

type ChatLauncherButtonProps = {
  onToggle: () => void;
  isOpen: boolean;
  buttonColor: string;
  iconSrc?: string;
  openChatText?: string;
  closeChatText?: string;
  size?: ChatLauncherButtonSizeOption;
};

// ChatLauncherButton is the base of the button that will launch the public chat iframe.
// NOTE: We must use inline styles and cannot use css modules because web components do not support
// css modules and the AssembledLauncherButton is based off of web compnents
export function ChatLauncherButton({
  onToggle,
  isOpen,
  buttonColor,
  iconSrc,
  openChatText = 'Open chat',
  closeChatText = 'Close chat',
  size = 'medium',
}: ChatLauncherButtonProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { buttonSize, iconSize, textLeft, fontSize } = computeSizeStyleSettings(size);

  const buttonText = React.useMemo(() => {
    return isOpen ? closeChatText : openChatText;
  }, [isOpen, closeChatText, openChatText]);

  const expandedWidth = React.useMemo(() => {
    try {
      const textLeftPixels = parsePixelString(textLeft);
      return computeExpandedWidth(buttonText, textLeftPixels, fontSize, buttonSize);
    } catch (error) {
      console.error('[Assembled] Error computing launcher width:', error);
    }
  }, [buttonText, textLeft, fontSize, buttonSize]);

  const wrapperStyle: CSSProperties = {
    position: 'relative',
    display: 'block',
    width: 'auto',
    height: 'auto',
  };

  const buttonStyle: CSSProperties = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: buttonSize,
    width: buttonSize,
    borderRadius: '9999px',
    border: 'none',
    backgroundColor: buttonColor,
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    transition: 'all 120ms ease-in-out',
    cursor: 'pointer',
  };

  // Dynamic styles
  const dynamicButtonStyle: CSSProperties = {
    ...(isHovered && {
      width: expandedWidth,
      transform: 'scale(1.05)',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    }),
    ...(isFocused && {
      outline: 'none',
      boxShadow:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1),' +
        ' 0 4px 6px -2px rgba(0, 0, 0, 0.05),' +
        ' 0 0 0 2px #fff,' +
        ' 0 0 0 4px #3b82f6',
    }),
  };

  const buttonCombinedStyle: CSSProperties = {
    ...buttonStyle,
    ...dynamicButtonStyle,
  };

  const iconContainerStyle: CSSProperties = {
    position: 'absolute',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: buttonSize,
    height: buttonSize,
    transition: 'all 120ms ease-in-out',
  };

  const iconStyle = {
    transition: 'transform 120ms ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: iconSize,
    height: iconSize,
  };

  const dynamicIconStyle = {
    ...iconStyle,
    ...(isHovered && {
      transform: isOpen ? 'rotate(90deg)' : 'scale(1.1)',
    }),
  };

  const textContainerStyle: CSSProperties = {
    position: 'absolute',
    left: textLeft,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    transition: 'all 120ms ease-in-out',
    opacity: 0,
    transform: 'translateX(-1rem)',
  };

  const dynamicTextContainerStyle: CSSProperties = {
    ...textContainerStyle,
    ...(isHovered && {
      opacity: 1,
      transform: 'translateX(0)',
    }),
  };

  const textColor = getContrastTextColor(buttonColor);
  const textStyle: CSSProperties = {
    fontSize,
    fontWeight: LAUNCHER_FONT_WEIGHT,
    color: textColor,
  };

  // Unfortunately, we can't use standard onClick, onFocus, etc. synthetic events from React 16 because
  // they don't work with shadow DOMs that we're using with webcomponents. Therefore, we need to roll our own
  // event listener handlers for this button component
  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      const handleMouseEnter = () => {
        setIsHovered(true);
      };
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
      const handleFocus = () => setIsFocused(true);
      const handleBlur = () => setIsFocused(false);
      const handleClick = onToggle;

      button.addEventListener('mouseenter', handleMouseEnter);
      button.addEventListener('mouseleave', handleMouseLeave);
      button.addEventListener('focus', handleFocus);
      button.addEventListener('blur', handleBlur);
      button.addEventListener('click', handleClick);

      return () => {
        button.removeEventListener('mouseenter', handleMouseEnter);
        button.removeEventListener('mouseleave', handleMouseLeave);
        button.removeEventListener('focus', handleFocus);
        button.removeEventListener('blur', handleBlur);
        button.removeEventListener('click', handleClick);
      };
    }
  }, [onToggle, setIsHovered, setIsFocused]);

  return (
    <div style={wrapperStyle}>
      <button type="button" ref={buttonRef} style={buttonCombinedStyle} aria-label="Chat Launcher">
        <div style={iconContainerStyle}>
          <div style={dynamicIconStyle}>
            {isOpen ? (
              <CloseIcon iconSize={iconSize} iconColor={textColor} />
            ) : (
              <MessageIcon iconSize={iconSize} iconColor={textColor} iconSrc={iconSrc} />
            )}
          </div>
        </div>

        <div style={dynamicTextContainerStyle}>
          <span style={textStyle}>{buttonText}</span>
        </div>
      </button>
    </div>
  );
}

class AssembledChatLauncher extends HTMLElement {
  private container: HTMLDivElement | null = null;

  private isOpen = false;

  static get observedAttributes(): string[] {
    return ['button-color', 'size', 'is-open'];
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback(): void {
    // Create a container for React
    this.container = document.createElement('div');
    this.shadowRoot?.appendChild(this.container);
    this.isOpen = this.getAttribute('is-open') === 'true';

    // Add global styles to shadow DOM
    const style = document.createElement('style');
    style.textContent = `
      :host {
        display: block;
        position: fixed;
        bottom: 16px;
        right: 16px;
        z-index: 99999;
        width: auto;
        height: auto;
      }
      
      div {
        display: block;
        position: relative;
        width: auto;
        height: auto;
      }
    `;
    this.shadowRoot?.appendChild(style);

    // Initial render
    this.render();
  }

  disconnectedCallback(): void {
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container);
    }
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue !== newValue) {
      if (name === 'is-open') {
        this.isOpen = newValue === 'true';
        this.render();
      } else {
        this.render();
      }
    }
  }

  private handleToggle = (): void => {
    // Dispatch event without changing isOpen
    this.dispatchEvent(
      new CustomEvent('assembled-chat-launcher-click', {
        bubbles: true,
        composed: true,
      })
    );
  };

  private render(): void {
    if (!this.container) return;

    const buttonColor = this.getAttribute('button-color') || DefaultButtonColor;
    const size = (this.getAttribute('size') as ChatLauncherButtonSizeOption) || DefaultSize;
    const iconSrc = this.getAttribute('icon-src') || undefined;
    const openChatText = this.getAttribute('open-chat-text') || undefined;
    const closeChatText = this.getAttribute('close-chat-text') || undefined;

    ReactDOM.render(
      <ChatLauncherButton
        onToggle={this.handleToggle}
        isOpen={this.isOpen}
        buttonColor={buttonColor}
        size={size}
        iconSrc={iconSrc}
        openChatText={openChatText}
        closeChatText={closeChatText}
      />,
      this.container
    );
  }
}

export default AssembledChatLauncher;
