import type { ChatLauncherButtonSizeOption } from './components/cal/publicchat/AssembledChatLauncher';
import { createAssembledChat, type AssembledInterface } from './components/cal/publicchat/AssembledChatWidget';
import { parseBoolean } from './components/cal/publicchat/ChatWidgetUtils';

declare global {
  interface Window {
    assembled: AssembledInterface | undefined;
  }
}

(async () => {
  // Get configuration from script tag
  const scriptTag = document.currentScript as HTMLScriptElement;
  let companyId = scriptTag?.getAttribute('data-company-id');
  if (!companyId && scriptTag?.src) {
    const scriptUrl = new URL(scriptTag.src, window.location.href);
    companyId = scriptUrl.searchParams.get('company_id') || null;
  }

  const profileId = scriptTag?.getAttribute('data-profile-id') || undefined;

  let activated: boolean | undefined;
  try {
    activated = parseBoolean(scriptTag?.getAttribute('data-activated')) || undefined;
  } catch (error) {
    console.error('[Assembled] Error setting chat activation:', error);
  }

  let disableAutoInit: boolean | undefined;
  try {
    disableAutoInit = parseBoolean(scriptTag?.getAttribute('data-disable-auto-init')) || undefined;
  } catch (error) {
    console.error('[Assembled] Error setting disable auto init:', error);
  }

  // Initialize the assembled chat with configuration
  window.assembled =
    window.assembled ||
    createAssembledChat({
      companyId: companyId || '',
      buttonColor: scriptTag?.getAttribute('data-button-color') || undefined,
      launcherSize: scriptTag?.getAttribute('data-launcher-size') as ChatLauncherButtonSizeOption | undefined,
      iconSrc: scriptTag?.getAttribute('data-icon-src') || undefined,
      activated,
      profileId,
    });

  if (window.assembled && !disableAutoInit) {
    window.assembled.init();
  }
})();
