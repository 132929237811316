export function parseBoolean(value: string | null | undefined): boolean | null {
  if (value === null || value === undefined) {
    return null;
  }

  const validTrueValues = ['1', 't', 'T', 'TRUE', 'true', 'True'];
  const validFalseValues = ['0', 'f', 'F', 'FALSE', 'false', 'False'];

  if (validTrueValues.includes(value)) {
    return true;
  }

  if (validFalseValues.includes(value)) {
    return false;
  }

  throw new Error(
    `Invalid boolean value: ${value}. Must be one of: ${[...validTrueValues, ...validFalseValues].join(', ')}`
  );
}
