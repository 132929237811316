import { throttle } from 'lodash-es';

import AssembledChatLauncher, {
  computeSizeStyleSettings,
  type ChatLauncherButtonSizeOption,
} from './AssembledChatLauncher';
import { AssembledMessage, CalPublicChatSettings, UserData, type CalPublicChatSettingsAndActivation } from './models';

export interface AssembledInterface {
  init(): Promise<void>;
  open(): void;
  close(): void;
  teardown(): void;
  authenticateUser(jwtToken: string, userData?: UserData): Promise<void>;
  updateUserData(userData: Partial<UserData>): Promise<void>;
  isReady(): boolean;
}

interface AssembledChatOptions {
  companyId: string;
  profileId?: string;
  activated?: boolean;
  jwtToken?: string;
  userData?: UserData;
  buttonColor?: string;
  launcherSize?: ChatLauncherButtonSizeOption;
  iconSrc?: string;
}

const MOBILE_WIDTH_BREAKPOINT_PX = 480;
export class AssembledChatWidget implements AssembledInterface {
  private iframe: HTMLIFrameElement;

  private launcher: HTMLElement;

  private isLoaded: boolean = false;

  private isOpen: boolean = false;

  private readonly baseDomain: string;

  private readonly companyId: string | undefined;

  private readonly profileId: string | undefined;

  private activated: boolean;

  private settingsAndActivation: CalPublicChatSettingsAndActivation | undefined;

  private jwtToken?: string;

  private userData?: UserData;

  private overrideActivation?: boolean;

  private serverActivation?: boolean;

  private buttonColor?: string;

  private launcherSize?: ChatLauncherButtonSizeOption;

  private iconSrc?: string;

  private openChatText?: string;

  private closeChatText?: string;

  // Event handlers
  private messageHandler: ((e: MessageEvent) => void) | null = null;

  private resizeHandler: (() => void) | null = null;

  private launcherClickHandler: (() => void) | null = null;

  private loadEventHandler: (() => Promise<void>) | null = null;

  constructor(options: AssembledChatOptions) {
    this.iframe = document.createElement('iframe');
    this.launcher = document.createElement('assembled-chat-launcher');
    this.baseDomain = process.env.BUILD_ENV === 'production' ? 'https://cal.assembledhq.com' : 'http://localhost:8080';

    this.jwtToken = options?.jwtToken || retrieveJWTToken(options.companyId);
    this.userData = options?.userData || undefined;
    this.companyId = options.companyId;
    this.profileId = options.profileId || undefined;
    this.buttonColor = options?.buttonColor || undefined;
    this.launcherSize = options?.launcherSize || undefined;
    this.iconSrc = options?.iconSrc || undefined;
    this.overrideActivation = options?.activated || undefined;
    this.activated = false;

    if (!this.companyId) {
      console.error(
        '[Assembled] You must provide a company_id in your script url parameters or constructor options to initialize the Assembled chat'
      );
    }
    if (this.jwtToken) {
      this.authenticateUser(this.jwtToken, this.userData);
    }
  }

  private setActivationState(shouldActivate: boolean): void {
    this.activated = shouldActivate;

    if (this.activated) {
      this.showLauncher();
    } else {
      this.hideLauncher();
    }
  }

  private applyActivationSettings(): void {
    let effectiveActivation: boolean;

    if (this.overrideActivation !== undefined) {
      // Developer configured settings have highest priority
      effectiveActivation = this.overrideActivation;
    } else if (this.serverActivation !== undefined) {
      // Server settings have second priority
      effectiveActivation = this.serverActivation;
    } else {
      // Default is hidden
      effectiveActivation = false;
    }

    this.setActivationState(effectiveActivation);
  }

  private async waitForSettings(): Promise<void> {
    if (!this.companyId) return;

    try {
      await this.pollForLocalSettings({
        retryIntervalMs: 75,
        backoffFactor: 1.2,
        maxRetries: 30,
      });
    } catch (error) {
      console.error('[Assembled] No message received for settings:', error);
    }
  }

  private pollForLocalSettings(options: {
    retryIntervalMs: number;
    backoffFactor: number;
    maxRetries: number;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      let retries = 0;

      const checkSettings = () => {
        if (this.settingsAndActivation) {
          resolve();
        } else if (retries < options.maxRetries) {
          const INTERVAL_MS = retries < 3 ? 50 : options.retryIntervalMs * options.backoffFactor ** (retries - 3);
          setTimeout(checkSettings, INTERVAL_MS);
          retries += 1;
        } else {
          reject(new Error('Max retries exceeded waiting for settings'));
        }
      };

      checkSettings();
    });
  }

  private sendMessageSafely(message: AssembledMessage): void {
    if (this.isLoaded && this.iframe.contentWindow) {
      console.log('[Assembled] Sending message to iframe');
      this.iframe.contentWindow.postMessage(message, this.baseDomain);
    } else {
      window.addEventListener(
        'assembledload',
        () => {
          console.log('[Assembled] Sending message after iframe load');
          if (this.iframe.contentWindow) {
            this.iframe.contentWindow.postMessage(message, this.baseDomain);
          }
        },
        { once: true }
      );
    }
  }

  private adjustIframePosition(): void {
    const iframeHeight = this.iframe.offsetHeight;
    const availableHeight = window.innerHeight - 20;
    const availableWidth = window.innerWidth;
    const isMobile = availableWidth <= MOBILE_WIDTH_BREAKPOINT_PX;
    const { iframeBottom } = computeSizeStyleSettings(this.launcherSize);

    if (isMobile) {
      this.iframe.style.top = '0';
      this.iframe.style.bottom = '0';
    } else if (iframeHeight + 80 > availableHeight) {
      this.iframe.style.top = '10px';
      this.iframe.style.bottom = 'auto';
    } else {
      this.iframe.style.top = 'auto';
      this.iframe.style.bottom = iframeBottom;
    }
  }

  private injectStyles(): void {
    const styleElement = document.createElement('style');
    const cssStyles = `
      iframe.assembled-chat {
        box-sizing: border-box;
        position: fixed;
        border: 0;
        margin: 0;
        padding: 0;
        z-index: 99999999;
        outline: none;
        display: none;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;
        max-width: 440px;
        max-height: 660px;
        width: calc(100vw - 32px);
        height: calc(100vh - 160px);
        right: 16px;
        bottom: 80px;
      }

      @media (max-width: ${MOBILE_WIDTH_BREAKPOINT_PX}px) {
        iframe.assembled-chat {
          width: 100vw;
          height: 100vh;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 0;
          max-width: 100%;
          max-height: 100%;
        }
      }
    `;
    styleElement.appendChild(document.createTextNode(cssStyles));
    document.head.appendChild(styleElement);
  }

  private setupIframe(): void {
    this.iframe.classList.add('assembled-chat');

    const urlParams = new URLSearchParams();
    if (this.companyId) {
      urlParams.set('company_id', this.companyId);
      this.iframe.setAttribute('data-company-id', this.companyId);
    }
    if (this.profileId) {
      urlParams.set('profile_id', this.profileId);
      this.iframe.setAttribute('data-profile-id', this.profileId);
    }

    const widgetUrl = `${this.baseDomain}/public_chat?${urlParams.toString()}`;
    this.iframe.src = widgetUrl;

    this.iframe.addEventListener('load', () => {
      console.log('[Assembled] Widget loaded');
    });

    this.adjustIframePosition();
    document.body.appendChild(this.iframe);
  }

  private setupResizeHandler(): void {
    this.resizeHandler = throttle(() => this.adjustIframePosition(), 250);
    window.addEventListener('resize', this.resizeHandler);
  }

  private setupLauncher(): void {
    if (this.buttonColor) {
      this.launcher.setAttribute('button-color', this.buttonColor);
    }
    if (this.launcherSize) {
      this.launcher.setAttribute('size', this.launcherSize);
    }
    if (this.iconSrc) {
      this.launcher.setAttribute('icon-src', this.iconSrc);
    }
    if (this.openChatText) {
      this.launcher.setAttribute('open-chat-text', this.openChatText);
    }
    if (this.closeChatText) {
      this.launcher.setAttribute('close-chat-text', this.closeChatText);
    }

    this.launcherClickHandler = () => {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    };
    this.launcher.addEventListener('assembled-chat-launcher-click', this.launcherClickHandler);
    this.applyActivationSettings();
    document.body.appendChild(this.launcher);
  }

  private updateLauncherAttributesIfNotSet(settings: Partial<CalPublicChatSettings>): void {
    if (settings.launcher_button_color && typeof this.buttonColor === 'undefined') {
      this.buttonColor = settings.launcher_button_color;
    }
    if (settings.launcher_size && typeof this.launcherSize === 'undefined') {
      this.launcherSize = settings.launcher_size;
    }
    if (settings.launcher_icon_url && typeof this.iconSrc === 'undefined') {
      this.iconSrc = settings.launcher_icon_url;
    }
    if (settings.launcher_open_chat_text && typeof this.openChatText === 'undefined') {
      this.openChatText = settings.launcher_open_chat_text;
    }
    if (settings.launcher_close_chat_text && typeof this.closeChatText === 'undefined') {
      this.closeChatText = settings.launcher_close_chat_text;
    }
  }

  public hideLauncher(): void {
    this.launcher.style.display = 'none';
  }

  public showLauncher(): void {
    this.launcher.style.display = 'block';
  }

  private setupMessageHandlers(): void {
    this.messageHandler = (e: MessageEvent) => {
      const { data } = e;
      if (typeof data !== 'object' || !data) return;

      switch (data.type) {
        case 'ASSEMBLED_ACTIVE':
          this.open();
          break;
        case 'ASSEMBLED_INACTIVE':
          this.close();
          break;
        case 'ASSEMBLED_LOADED':
          if (!this.isLoaded) {
            window.dispatchEvent(new CustomEvent('assembledload'));
            this.isLoaded = true;
          }
          break;
        case 'ASSEMBLED_LOADED_SETTINGS':
          this.settingsAndActivation = data.settings_and_activation;
          this.updateLauncherAttributesIfNotSet(data.settings_and_activation.settings);
          this.serverActivation = data.settings_and_activation.activation.activated;
          this.applyActivationSettings();
          this.adjustIframePosition();
          break;
        case 'ASSEMBLED_CLOSE':
          this.close();
          window.dispatchEvent(new CustomEvent('assembledclose'));
          break;
      }
    };

    window.addEventListener('message', this.messageHandler);
  }

  public async init(): Promise<void> {
    if (!customElements.get('assembled-chat-launcher')) {
      customElements.define('assembled-chat-launcher', AssembledChatLauncher);
    }

    const loadWidget = async (): Promise<void> => {
      this.applyActivationSettings();

      this.injectStyles();
      this.setupIframe();
      this.setupMessageHandlers();
      this.setupResizeHandler();
      await this.waitForSettings();
      this.setupLauncher();
    };

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      await loadWidget();
    } else {
      this.loadEventHandler = loadWidget;
      document.addEventListener('DOMContentLoaded', this.loadEventHandler, { once: true });
    }
  }

  // authenticateUser allows for secure authentication using JWTs
  public async authenticateUser(jwtToken: string, userData?: UserData): Promise<void> {
    try {
      this.jwtToken = jwtToken;
      this.userData = this.mergeUserData(userData);
      this.updateUserDataState();
    } catch (error) {
      console.error('[Assembled] Authentication failed:', error);
      throw error;
    }
  }

  public async updateUserData(userData: Partial<UserData>): Promise<void> {
    try {
      this.userData = this.mergeUserData(userData);
      this.updateUserDataState();
    } catch (error) {
      console.error('[Assembled] Failed to update user data:', error);
      throw error;
    }
  }

  private updateUserDataState(): void {
    this.sendMessageSafely({
      type: 'USER_DATA_UPDATE',
      jwtToken: this.jwtToken,
      userData: this.userData,
    });
  }

  private mergeUserData(newData: Partial<UserData> | undefined): UserData | undefined {
    if (!newData) {
      return this.userData;
    }

    return {
      ...this.userData,
      ...newData,
      metadata: { ...(this.userData?.metadata || {}), ...(newData.metadata || {}) },
    };
  }

  public open(): void {
    this.iframe.style.display = 'block';
    this.isOpen = true;
    this.launcher.setAttribute('is-open', 'true');
    this.sendMessageSafely({ isVisible: true });
  }

  public close(): void {
    this.iframe.style.display = 'none';
    this.isOpen = false;
    this.launcher.setAttribute('is-open', 'false');
    this.sendMessageSafely({ isVisible: false });
  }

  public isReady(): boolean {
    return this.isLoaded;
  }

  public teardown(): void {
    // Remove event listeners
    if (this.messageHandler) {
      window.removeEventListener('message', this.messageHandler);
    }
    if (this.resizeHandler) {
      window.removeEventListener('resize', this.resizeHandler);
    }
    if (this.launcherClickHandler) {
      this.launcher.removeEventListener('assembled-chat-launcher-click', this.launcherClickHandler);
    }
    if (this.loadEventHandler) {
      document.removeEventListener('DOMContentLoaded', this.loadEventHandler);
    }

    // Remove DOM elements
    this.iframe.remove();
    this.launcher.remove();

    // Clean up references
    this.messageHandler = null;
    this.resizeHandler = null;
    this.launcherClickHandler = null;
    this.loadEventHandler = null;
  }
}

function retrieveJWTToken(companyId: string): string | undefined {
  // Tithely uses a specific JWT token for authentication
  if (companyId === 'd42e7463-e883-4499-af23-37eaf7b4f2c0') {
    return retrieveCookie('tithely_auth');
  }

  return undefined;
}

export function retrieveCookie(cookieName: string): string | undefined {
  return parseCookie(document.cookie, cookieName);
}

export function parseCookie(documentCookie: string, cookieName: string): string | undefined {
  const value = `; ${documentCookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts.length === 2) {
    const lastPart = parts.pop();
    return lastPart?.split(';')[0];
  }
  return undefined;
}

export function createAssembledChat(options: AssembledChatOptions): AssembledInterface {
  return new AssembledChatWidget(options);
}
