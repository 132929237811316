// Patch for PublicChat from assets/src/styles/Colors.ts

export const getValidHexColor = (hex: string | null): string => {
  if (!hex) return '000000';
  let validHex = String(hex).replace(/[^0-9a-f]/gi, '');
  validHex = validHex.padEnd(3, '0');
  if (validHex.length < 6) {
    validHex = `${validHex[0]}${validHex[0]}${validHex[1]}${validHex[1]}${validHex[2]}${validHex[2]}`;
  }
  return validHex;
};

export const hexToRGB = (
  hex: string
): {
  r: number;
  g: number;
  b: number;
} => {
  const c = parseInt(hex, 16);
  return {
    /* eslint-disable no-bitwise */
    r: (c >> 16) & 0xff,
    g: (c >> 8) & 0xff,
    b: (c >> 0) & 0xff,
    /* eslint-enable no-bitwise */
  };
};

const computeLuminance = (r: number, g: number, b: number): number => {
  // ITU-R BT.709-6
  // https://www.itu.int/dms_pubrec/itu-r/rec/bt/R-REC-BT.709-6-201506-I!!PDF-E.pdf
  // page no: 6 / luminance calculation
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export function getContrastTextColor(hexBackgroundColor: string): string {
  const alphanumericHex = hexBackgroundColor.replace(/^#/, '');
  const cleanHex = getValidHexColor(alphanumericHex);
  const { r, g, b } = hexToRGB(cleanHex);
  const luminance = computeLuminance(r, g, b);

  // Choose black or white text depending on the luminance threshold. 128 is the midpoint between 0 and 255
  // so it serves as a heuristic for where something should change to black vs white text
  return luminance >= 128 ? '#000000' : '#ffffff';
}
